import React, { useState, useEffect } from "react"
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"

function Freetrial({ location }) {
  const urlParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState(urlParams.get('email') || '');
  const [phone, setPhone] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [company, setCompany] = useState("")
  const [source, setSource] = useState("")
  const [medium, setMedium] = useState("")
  const [campaign, setCampaign] = useState("")
  const [gclid, setGclid] = useState("")
  const [leadsource, setLeadsource] = useState("")
  const [promocode, setPromocode] = useState("")

  const [disabled, setDisabled] = useState(false)
  const { register, handleSubmit } = useForm()
  let formtype = "freetrial";

  useEffect(() => {
    window.location = "https://try.inspectionsupport.com/";
    setLeadsource("")
    if ('ga' in window) {
      window.ga(function (tracker) {
        var trackers = window.ga.getAll()
        if (trackers.referrer) {
          setSource(trackers.referrer)
        }
      })
    }
    let params = new URLSearchParams(location.search)
    if (params.get("campaignid")) {
      setCampaign(params.get("campaignid"))
    }
    if (params.get("medium")) {
      setMedium(params.get("medium"))
    }
    if (params.get("source")) {
      setSource(params.get("source"))
    }
    if (params.get("utm_campaign")) {
      setCampaign(params.get("utm_campaign"))
    }
    if (params.get("utm_medium")) {
      setMedium(params.get("utm_medium"))
    }
    if (params.get("utm_source")) {
      setSource(params.get("utm_source"))
    }
    if (params.get("gclid")) {
      setGclid(params.get("gclid"))
    }

    window.scrollTo(0, 0)
  }, [location.search])

  const submitContactForm = async event => {
    setDisabled(true)
    if (typeof window.ga === "function") {
      window.ga("send", "event", "Orderpage", "Formsubmit")
    }
    const url =
      "https://6mjv6ximbh.execute-api.us-west-2.amazonaws.com/default/isn-www-salesforce?type=" +
      formtype +
      "&firstname=" +
      firstname +
      "&lastname=" +
      lastname +
      "&company=" +
      company +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&source=" +
      source +
      "&medium=" +
      medium +
      "&campaign=" +
      campaign +
      "&gclid=" +
      gclid +
      "&leadsource=" +
      leadsource +
      "&promocode=" +
      promocode
    let encodedurl = encodeURI(url)
    fetch(encodedurl).then(function () {
      navigate("/thankyou/")
    })
  }

  return (
    <div>
      <Helmet
        title="Free Trial: Get 30 Days Free - Inspection Support Network"
        defer={false}
      />
      <Container>
        <Header />
      </Container>
      <Container className={'legacy-page-spacer'} fluid>
        <Row style={{ backgroundColor: "#f3f4f6" }}>
          <Container>
            <Row style={{ paddingTop: "50px" }}>
              <Col sm={12} md={8}>
                <h1
                  id="signupform"
                  className="headerreplacement text-center text-md-left mb-8 mb-md-0"
                  style={{ fontWeight: 600 }}
                >
                  Join the thousands of home inspectors who trust ISN to help
                  them manage and grow their businesses.
                </h1>
                <br />
                <br />
                <p className=" text-center text-md-left mb-4 mb-md-0">
                  <Image
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/check.png"
                    style={{ width: "30px", fontSize: "24px" }}
                  />{" "}
                  Save time with simple automation tools
                </p>
                <br />
                <p className=" text-center text-md-left mb-4 mb-md-0">
                  <Image
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/check.png"
                    style={{ width: "30px", fontSize: "24px" }}
                  />{" "}
                  Measure results with easy-to-use reporting
                </p>
                <br />
                <p className=" text-center text-md-left mb-4 mb-md-0">
                  <Image
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/check.png"
                    style={{ width: "30px", fontSize: "24px" }}
                  />{" "}
                  Personalized, hands on customer support
                </p>
                <br />
                <p className=" text-center text-md-left mb-4 mb-md-0">
                  <Image
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/check.png"
                    style={{ width: "30px", fontSize: "24px" }}
                  />{" "}
                  Streamline scheduling, booking, and billing
                </p>
              </Col>
              <Col sm={12} md={4}>
                <br />
                <div
                  id="signupform"
                  style={{
                    backgroundColor: "white",
                    padding: "25px",
                    borderRadius: "3px",
                  }}
                >
                  <h3
                    style={{
                      color: "#3878d8",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    Start your free 30 day trial
                  </h3>
                  <br />
                  <Form onSubmit={handleSubmit(submitContactForm)}>
                    <Form.Group controlId="company">
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        name="company"
                        type="company"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        ref={register({ required: true })}
                      />
                    </Form.Group>

                    <Form.Group controlId="firstname">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="firstname"
                        type="firstname"
                        value={firstname}
                        onChange={e => setFirstname(e.target.value)}
                        ref={register({ required: true })}
                      />
                    </Form.Group>

                    <Form.Group controlId="lastname">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="lastname"
                        type="lastname"
                        value={lastname}
                        onChange={e => setLastname(e.target.value)}
                        ref={register({ required: true })}
                      />
                    </Form.Group>

                    <Form.Group controlId="phone">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        name="phone"
                        type="phone"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        ref={register({ required: true })}
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        ref={register({ required: true })}
                      />
                    </Form.Group>

                    <Form.Group controlId="promocode">
                      <Form.Label>Promo Code (if applicable)</Form.Label>
                      <Form.Control
                        name="promocode"
                        type="promocode"
                        value={promocode}
                        onChange={e => setPromocode(e.target.value)}
                        ref={register({ required: false })}
                      />
                    </Form.Group>

                    <Form.Group controlId="landingPageName">
                      <Form.Control
                        name="landingpagename"
                        type="hidden"
                        value="FreeTrial"
                      ></Form.Control>
                    </Form.Group>

                    <br />
                    <Button
                      disabled={disabled}
                      style={{
                        backgroundColor: "green",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                      type="submit"
                    >
                      {!disabled ? "GET STARTED" : "Submitting..."}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
        <Row style={{ backgroundColor: "#26292b" }}>
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <div style={{ width: "85%", margin: "0 auto" }}>
                  <p className="subhead-largest white text-center text-md-left">
                    <strong>What is the Inspection Support Network? </strong>
                  </p>
                  <br />
                  <p className="white subhead-large text-center text-md-left mb-4 mb-md-0">
                    The Inspection Support Network is the industry’s most
                    trusted home inspection software solution. With an
                    incomparable portfolio of easy-to-use tools, ISN enables
                    home inspectors to simplify, streamline, and grow their
                    businesses. Whether you’re a sole proprietor or have a team
                    of 20, ISN can help you build your brand, automate your
                    workflow, and manage your customer and agent relationships.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6}>
                <img
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-1.png"
                  className="d-none d-md-block"
                  style={{ width: "100%" }}
                  alt="free trial"
                />
              </Col>
            </Row>
          </Container>
        </Row>
        <Row style={{ backgroundColor: "white" }}>
          <Container>
            <Row>
              <Col sm={12} md={4} className="text-center">
                <h3 className="green">120K</h3>
                <p>inspections processed per month</p>
              </Col>
              <Col sm={12} md={4} className="text-center">
                <h3 className="green">24/7</h3>
                <p>access to friendly support</p>
              </Col>
              <Col sm={12} md={4} className="text-center">
                <h3 className="green">4K+</h3>
                <p>home inspectors using platform</p>
              </Col>
            </Row>
          </Container>
        </Row>
        <Row style={{ backgroundColor: "#e9e9e9" }}>
          <Container>
            <Row>
              <Col
                sm={12}
                className="text-center bold"
                style={{ fontWeight: "bold" }}
              >
                <div style={{ margin: "0px 0px 50px 0px" }}>
                  See what inspectors are saying about ISN
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="testimonial">
                  <img
                    className="testimonial-quote"
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/quotes.png"
                    alt=""
                  />
                  <div>
                    <p className="testimonial-body">
                      After switching over to the ISN, I have not regretted a
                      single moment…I am a numbers person and I know the ISN is
                      one of the reasons my company has continued to grow year
                      after year. The ISN system is not only the best online
                      scheduling system on the market, but it also provides
                      great company branding during and after the inspection.
                    </p>
                  </div>
                  <div className="testimonial-block">
                    <img
                      className="testimonial-image"
                      src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/brian.png"
                      alt=""
                    />
                    <p className=" align-self-center testimonial-small">
                      Brian P. Murphy
                      <br />
                      A-Action Home Inspection Group
                      <br />
                      www.a-actionhomeinspection.com
                    </p>
                  </div>
                </div>
                <br />
                <div className="testimonial">
                  <img
                    className="testimonial-quote"
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/quotes.png"
                    alt=""
                  />
                  <div>
                    <p className="testimonial-body">
                      As a multi-faceted consulting and assessment firm, we
                      needed to find a single solution that still allows us to
                      meet the unique requirements of our diverse clientele. ISN
                      has given us the flexibility to conform to individual
                      client requests while remaining consistent with the
                      platform and process…As a company, we have such confidence
                      and trust in the qualities of ISN.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="testimonial-block">
                    <img
                      className="testimonial-image"
                      src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/kristi.png"
                      alt=""
                    />
                    <p className=" align-self-center testimonial-small">
                      Kristi Uhland
                      <br />
                      Skyetec
                      <br />
                      www.skyetec.com
                    </p>
                  </div>
                </div>
                <br />
              </Col>
              <Col sm={12} md={6}>
                <div className="testimonial">
                  <img
                    className="testimonial-quote"
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/quotes.png"
                    alt=""
                  />
                  <div>
                    <p className="testimonial-body">
                      I am able to market to lenders and they love the fact that
                      the automated access to recording information there is no
                      one else doing that in my market… I am now generating and
                      increasing my market share for 203k consultations because
                      I am giving my lenders and contractors information and
                      resources they had no other consultant giving them.
                    </p>
                  </div>
                  <div className="testimonial-block">
                    <img
                      className="testimonial-image"
                      src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/catherine.png"
                      alt=""
                    />
                    <p className=" align-self-center testimonial-small">
                      Catherine Hall
                      <br />
                      203k In a Box
                      <br />
                      www.203kinabox.com
                    </p>
                  </div>
                </div>
                <br />
                <div className="testimonial">
                  <img
                    className="testimonial-quote"
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/quotes.png"
                    alt=""
                  />
                  <div>
                    <p className="testimonial-body">
                      The customer support cannot be better…It’s so nice to be
                      able to have people patient since we are not computer
                      technical type of people. To be able to have that extra
                      support and have the patience for someone who just isn’t
                      that technical whether it’s myself or my wife who does run
                      most of the scheduling and uses it…To get up and running
                      to do the baseline type of work is done very, very
                      rapidly.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="testimonial-block">
                    <img
                      className="testimonial-image"
                      src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/john.png"
                      alt=""
                    />
                    <p className=" align-self-center testimonial-small">
                      John Tyler
                      <br />
                      Checklist Inspections
                      <br />
                      www.checklistaz.com
                    </p>
                  </div>
                </div>
                <br />
              </Col>
            </Row>
          </Container>
        </Row>
        <Row style={{ backgroundColor: "white" }}>
          <Container fluid>
            <Row>
              <Col
                sm={12}
                className="text-center bold"
                style={{ fontWeight: "bold" }}
              >
                <div
                  style={{
                    padding: "0px 0px 0px 0px",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  Trusted by the best brands and integrated with the top tools
                  in the home inspection industry
                </div>
                <br />
                <br />
              </Col>
              <Col sm={12} className="text-center">
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-2.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-3.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-4.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-5.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-6.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-7.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-8.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px", maxWidth: "200px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-9.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-10.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-11.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-12.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-13.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px", maxWidth: "200px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-14.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-15.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-16.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-17.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-18.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-19.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-20.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-21.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-22.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-23.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-24.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-26.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-27.png"
                />
                <img
                  className="imgnomod"
                  alt="partner logo"
                  style={{ padding: "20px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-28.png"
                />
              </Col>
            </Row>
          </Container>
        </Row>
        <Row style={{ backgroundColor: "#3878d8", marginBottom: "0px" }}>
          <Container>
            <Row>
              <Col
                sm={12}
                className="text-center white bold"
                style={{ fontWeight: "bold" }}
              >
                <div style={{ margin: "0px 0px 50px 0px" }}>
                  The value of the Inspection Support Network
                </div>
              </Col>
              <Col sm={12} md={4} className="text-center text-md-left">
                <div className="subhead-large white">
                  <Image
                    style={{
                      width: "35px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-mini2.png"
                  />
                  Business&nbsp;Growth
                </div>
                <p className="white" style={{ lineHeight: "1.5em" }}>
                  With ISN, you can take your home inspection business to the
                  next level. We’ll help you build your brand, streamline your
                  marketing processes, and further develop your customer
                  relationships. With our time-saving tools, you can increase
                  both your efficiency and your profits.
                </p>
              </Col>
              <Col sm={12} md={4} className="text-center text-md-left">
                <div className="subhead-large white">
                  <Image
                    style={{
                      width: "35px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-mini3.png"
                  />
                  Workflow&nbsp;Automation
                </div>
                <p className="white" style={{ lineHeight: "1.5em" }}>
                  With ISN automation tools, you’ll save time, money and
                  resources on every inspection. We’ll help you simplify your
                  day-to-day through automated scheduling, report delivery,
                  email follow-up and billing. We’re the only industry solution
                  that integrates with every report writing software out there,
                  to help make sure your processes are simplified.
                </p>
              </Col>
              <Col sm={12} md={4} className="text-center text-md-left">
                <div className="subhead-large white">
                  <Image
                    style={{
                      width: "35px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/freetrial/freetrial-mini4.png"
                  />
                  Personalized&nbsp;Support
                </div>
                <p className="white" style={{ lineHeight: "1.5em" }}>
                  We pride ourselves in providing our customers with exceptional
                  service. Your success is our business, and we’re committed to
                  making sure you get the most value possible out of your
                  partnership with ISN. 1:1 training, troubleshooting and
                  support are all included with ISN software.
                </p>
              </Col>
              <Col
                sm={12}
                className="text-center white subhead"
                style={{ fontWeight: "bold" }}
              >
                <br />
                <br />
                <a href="/freetrial/">
                  <button className="free-trail-join-button">
                    JOIN THE ISN FAMILY
                  </button>
                </a>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default Freetrial
